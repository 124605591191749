import { useEffect, useState } from "react";
import { differenceInDays, isAfter } from "date-fns";

const useDateValidator = (startDate, endDate) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const validateDateRange = () => {
      if (isAfter(startDate, endDate))
        return setMessage("Start date should not be a future of end date!");
      if (differenceInDays(endDate, startDate) > 30)
        return setMessage("Date range should not be above 30 days!");
      return setMessage("");
    };

    validateDateRange();
  }, [startDate, endDate]);

  return message;
};

export default useDateValidator;
