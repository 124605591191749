import BarChartOverview from "components/BarChartOverview/BarChartOverview";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
import { fetchApiLogs, fetchCosts } from "redux/actions/kycInstance";

import swal from "@sweetalert/with-react";
import SpendingLog from "pages/SpendingLog/SpendingLog";
import "react-daterange-picker/dist/css/react-calendar.css";
import "./overview.scss";
import { userDetails } from "apiServices/user.service";

const Overview = ({ currencies, fetchingCurrencies }) => {
  const dispatch = useDispatch();
  const { costsLoading, costsPayload, apiLogsLoading, apiLogsPayload } =
    useSelector(({ kycInstances }) => kycInstances);

  const [dateRange, setDateRange] = useState(null);
  const [activeCurrency, setActiveCurrency] = useState("");
  const [remount, setRemount] = useState(false);
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {
    if (!fetchingCurrencies && currencies) {
      setActiveCurrency(currencies[0]?.currency);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies, fetchingCurrencies]);

  useEffect(() => {
    dispatch(fetchApiLogs({ ...page, currency: activeCurrency }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (dateRange || activeCurrency) {
      dispatch(
        fetchCosts({
          currency: activeCurrency,
          startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
          endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
        })
      );
      dispatch(
        fetchApiLogs({
          ...page,
          currency: activeCurrency,
          startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
          endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
        })
      );
    }
    if (remount) {
      setRemount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, activeCurrency]);

  const onSelect = (value) => {
    const start = value.start;
    const end = value.end;
    const dateCount = end.diff(start, "days") + 1;
    if (dateCount > 30) {
      swal({
        text: "Kindly select range to be at most 30 days.",
        icon: "info",
        button: "Okay",
      });
    } else {
      setDateRange(value);
    }
  };

  const onClearSelection = () => {
    setDateRange(null);
  };

  const renderContent = (range) => {
    return (
      <>
        {!range
          ? "Monthly"
          : `${range?.start?.format("LL")} - ${range?.end?.format("LL")}`}
      </>
    );
  };

  const onSearchApiLog = _debounce((event) => {
    dispatch(fetchApiLogs({ ...page, search: event.target.value }));
  }, 1000);

  return (
    <div style={{ height: "100%" }}>
      <div className="sp-pt--12">
        <BarChartOverview
          loading={costsLoading}
          dataSource={costsPayload?.data?.data?.responseModel}
          totalReceived={costsPayload?.data?.data?.totalCost}
          currencies={currencies?.map((v) => v.currency) || []}
          fetchingCurrencies={fetchingCurrencies}
          selectedCurrency={activeCurrency}
          onClear={() => {
            setRemount(true);
            onClearSelection();
          }}
          remount={remount}
          dateRange={dateRange}
          renderContent={renderContent}
          onSelect={onSelect}
          onCurrencyTabClick={(data) => setActiveCurrency(data)}
        />
      </div>
      <div className="sp-pt--32">
        <SpendingLog
          payload={apiLogsPayload}
          loading={apiLogsLoading}
          onPageChange={(page) =>
            setPage((prev) => ({ ...prev, pageNumber: page }))
          }
          handleSearch={onSearchApiLog}
        />
      </div>
    </div>
  );
};

export default Overview;
