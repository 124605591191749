import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { useYupValidationResolver } from "helpers/yupValidationResolver";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import SelectControl from "components/selectControl/SelectControl";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { Table } from "vgg-ui-react/dist/components/Core";
import Select from "react-select";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  ConfigureKycInstance,
  LoadCountryKYCs,
  LoadServiceManagerCountries,
  LoadServiceManagerCurrencies,
} from "redux/actions/kycInstance";
import { successMessage, errorMessage } from "redux/actions/notification";
import { Spinner } from "reactstrap";
import URLS from "helpers/urls";
import "./configureKyc.scss";
import CustomSpinner from "shared/CustomSpinner/CustomSpinner";

const ConfigureKyc = ({ isChild }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const [sumbitStatus, setSubmitStatus] = useState(false);

  const {
    creatingInstance,
    createInstanceSuccess,
    createInstanceError,
    data,
    countries,
    currencies,
    loadingCountryKYCs,
    countryKYCs,
  } = useSelector(({ kycInstances }) => kycInstances);

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
  });
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
  } = useForm({ resolver });

  const { country } = watch();

  useEffect(() => {
    if (!creatingInstance && createInstanceSuccess && sumbitStatus) {
      setSubmitStatus(false);
      dispatch(successMessage("Instance configuration successful"));
      history.push({
        pathname: `${isChild ? URLS.KycService : "/"}`,
        state: { actionType: "configure" },
      });
    }
    if (!creatingInstance && createInstanceError && sumbitStatus) {
      setSubmitStatus(false);
      dispatch(
        errorMessage(
          createInstanceError?.message || "Instance configuration failed"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInstanceSuccess, creatingInstance]);

  const onSubmit = (data) => {
    setSubmitStatus(true);
    dispatch(
      ConfigureKycInstance(
        {
          ...data,
          wallet: data?.wallet?.value,
          country: data?.country?.value,
          isEnabled: true,
          id: +params?.id || undefined,
        },
        params?.id
      )
    );
  };

  useEffect(() => {
    dispatch(LoadServiceManagerCountries());
    dispatch(LoadServiceManagerCurrencies());
    return () => {
      reset();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countries?.items && currencies?.items && data) {
      setInitialValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, currencies, data]);

  useEffect(() => {
    if (country) {
      dispatch(LoadCountryKYCs({ countryCode: country.value }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const setInitialValue = () => {
    if (data?.length > 0) {
      if (params?.id) {
        const payload = data?.find((val) => +val.id === +params.id);

        if (payload) {
          setEditMode(true);
          const fields = ["name", "wallet", "country", "isEnabled"];
          fields.forEach((field) => {
            if (field === "wallet") {
              const { name: label, currencyCode: value } =
                currencies?.items?.find(
                  (v) => v?.currencyCode === payload[field]
                ) || {};
              setValue(field, { label, value });
            } else if (field === "country") {
              const { name: label, isO3: value } =
                countries?.items?.find((v) => v?.isO3 === payload[field]) || {};
              setValue(field, { label, value });
            } else {
              setValue(field, payload[field]);
            }
          });
        }
      }
    }
  };

  return (
    <div className="configure-container">
      <div
        className="pg-header d-flex align-items-center sp-pb--32"
        style={{ borderBottom: "1px solid #c3c3c3" }}
      >
        <span
          className="back-btn"
          onClick={() =>
            history.push({
              pathname: `${isChild ? URLS.KycService : "/"}`,
              state: { actionType: "configure" },
            })
          }
        >
          <i className="ri-arrow-left-s-line ri-2x"></i>
        </span>
        <Typography
          typographyVariant="disp-heading"
          text="Configure Kyc"
          className="font-weight-bold"
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="container">
        <div className="row sp-pt--48">
          <div className="col-sm-12 col-md-5 col-lg-4">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className={`form-control ${errors?.name ? "is-invalid" : ""}`}
                id="name"
                name="name"
                {...register("name", { required: true })}
              />
              <div className="invalid-feedback">{errors?.name?.message}</div>
            </div>

            <div className="mb-3">
              <label htmlFor="country" className="form-label">
                Country
              </label>
              <div data-testid="configure-controller">
                <Controller
                  control={control}
                  name="country"
                  id="country"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      {...register("country", { required: true })}
                      value={value}
                      onChange={(e) => onChange({ target: { value: e } })}
                      onBlur={onBlur}
                      inputRef={ref}
                      placeholder="Select Country"
                      controlClassName={`form-control ${
                        errors?.country ? "is-invalid" : ""
                      } ps-0 pt-0 pb-0`}
                      controlErrorMsg={errors?.country?.message}
                      components={{
                        IndicatorSeparator: () => null,
                        Control: SelectControl,
                      }}
                      options={
                        countries?.items?.map((v) => ({
                          label: v?.name,
                          value: v?.isO3,
                        })) || []
                      }
                    />
                  )}
                />
                <div className="invalid-feedback">
                  {errors?.country?.message}
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="wallet" className="form-label">
                Choose a Wallet
              </label>
              <div data-testid="configure-controller">
                <Controller
                  control={control}
                  name="wallet"
                  id="wallet"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      {...register("wallet", { required: true })}
                      value={value}
                      onChange={(e) => onChange({ target: { value: e } })}
                      onBlur={onBlur}
                      inputRef={ref}
                      controlClassName={`form-control ${
                        errors?.wallet ? "is-invalid" : ""
                      } ps-0 pt-0 pb-0`}
                      controlErrorMsg={errors?.wallet?.message}
                      components={{
                        IndicatorSeparator: () => null,
                        Control: SelectControl,
                      }}
                      options={
                        currencies?.items?.map((v) => ({
                          label: v?.name,
                          value: v?.currencyCode,
                        })) || []
                      }
                    />
                  )}
                />
                <div className="invalid-feedback">
                  {errors?.wallet?.message}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-7 offset-md-1 kyc-list">
            <Typography
              typographyVariant="subtitle1"
              text="KYC List"
              className="font-weight-bold sp-mb--24"
            />
            {loadingCountryKYCs ? (
              <CustomSpinner />
            ) : (
              <div className="row">
                {countryKYCs?.responseData?.length === 0 ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "75%" }}
                  >
                    <p className="text-muted">
                      {country
                        ? "No KYC for selected country"
                        : "No data available"}
                    </p>
                  </div>
                ) : (
                  <Table variant="unBounded" className="table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th style={{ textAlign: "right" }}>Cost Per Call</th>
                      </tr>
                    </thead>
                    <tbody style={{ borderTop: "none", background: "#f4f5f6" }}>
                      {countryKYCs?.responseData?.map((v, idx) => (
                        <tr key={v?.id}>
                          <td>{v?.kycName || "--"}</td>
                          <td>{v?.kycCode || "--"}</td>
                          <td style={{ textAlign: "right" }}>
                            {v?.costPerCall || "--"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="sp-pt--48 d-flex w-100 align-items-center justify-content-end">
          <div className="actionContainer me-3">
            <Button
              label="Cancel"
              variant="primary"
              type="button"
              className="bg-gray w-100"
              onClick={() =>
                history.push({
                  pathname: `${isChild ? URLS.KycService : "/"}`,
                  state: { actionType: "configure" },
                })
              }
            />
          </div>
          <div className="actionContainer me-3">
            <Button
              type="submit"
              label={
                <div className="d-flex justify-content-center">
                  <div>
                    {creatingInstance && <Spinner size="sm" color="light" />}
                    <span className="ms-2">
                      {editMode
                        ? `Updat${creatingInstance ? "ing..." : "e"}`
                        : `Creat${creatingInstance ? "ing..." : "e"}`}
                    </span>
                  </div>
                </div>
              }
              background="blue"
              className="w-100"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConfigureKyc;
