export default function checkBase64Prefix(inputString = "", imageType = "png") {
  const base64PrefixPNG = `data:image/png;base64,`;
  const base64PrefixJPEG = `data:image/jpeg;base64,`;
  const base64PrefixJPG = `data:image/jpg;base64,`;
  const base64PrefixGIF = `data:image/gif;base64,`;

  if (
    inputString.startsWith(base64PrefixPNG) ||
    inputString.startsWith(base64PrefixJPG) ||
    inputString.startsWith(base64PrefixJPEG) ||
    inputString.startsWith(base64PrefixGIF)
  ) {
    return inputString; // String already has the correct prefix
  }

  // Prepend the required prefix
  return base64PrefixPNG + inputString;
}
