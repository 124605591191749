import EmptyState from "components/emptyState/EmptyState";
import { LoadingTableContent } from "components/loader/Loader";
import getSymbolFromCurrency from "currency-symbol-map";
import CurrencyFormat from "react-currency-format";
import {Button} from "reactstrap";
import { format } from "date-fns";
import React, { useState } from "react";
import TablePagination from "shared/tablePagination/tablePagination";
import Table from "vgg-ui-react/dist/components/Core/Table";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import ExportModal from "./exportModal/exportModal"
import "./spendingLog.scss";

const SpendingLog = ({ payload, loading, onPageChange, handleSearch }) => {
  const [openExport, setOpenExport] = useState(false)
  return (
    <div>
      <div className="w-100">
        <Typography typographyVariant="section-title" text="KYC API Log" />
        <div className="d-flex justify-content-between align-items-end">
          <div className="sp-mt--16 sp-mb--24 search-container">
            <input type="text" placeholder="search" className="form-control" onChange={handleSearch} />
            <i className="ri-search-line"></i>
          </div>
          <Button color="primary" className="export-btn" onClick={()=>setOpenExport(true)}>Export</Button>
        </div>
        
      </div>
      <div className="sp-pb--32 w-100 d-flex flex-column align-items-center justify-content-between">
        <Table cellVariants="default" className="table-striped">
          <thead>
            <tr>
              <th width="20%">Merchant</th>
              <th width="10%">Status</th>
              <th width="20%">Amount</th>
              <th width="20%">Identity Type</th>
              <th width="15%">Date</th>
              <th width="15%">Time</th>
            </tr>
          </thead>
          {loading ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={6} />
            </tbody>
          ) : (
            <tbody style={{ borderTop: "none" }}>
              {payload?.data?.data?.responseModel?.items &&
                payload?.data?.data?.responseModel?.items?.map(val => (
                  <tr key={val?.id}>
                    <td>
                      <span className="fw-normal">{val?.merchantName || "--"}</span>
                    </td>
                    <td>{typeof val?.isSuccessful === "boolean" ? (val?.isSuccessful ? "Successful" : "Failed") : "--"}</td>
                    <td><CurrencyFormat
                        value={val?.cost}
                        displayType="text"
                        thousandSeparator
                        prefix={getSymbolFromCurrency(val?.currencyCode)}
                        renderText={value => <span>{value}</span>}
                        decimalScale={2}
                        fixedDecimalScale
                      /></td>
                    <td>{val?.identityType || "--"}</td>
                    <td> {val?.createdAt ? format(new Date(val?.createdAt), "dd MMMM, yyyy") : "--"}</td>
                    <td>{val?.createdAt ? format(new Date(val?.createdAt), "h:MM b") : "--"}</td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
        {!loading && payload?.data?.data?.responseModel?.items?.length < 1 ? <EmptyState /> : null}
        {payload?.data?.data?.responseModel?.items && payload?.data?.data?.responseModel?.items?.length > 0 && (
          <div className="my-3 w-100">
            <TablePagination
              data={payload?.data?.data?.responseModel?.items}
              totalCount={payload?.data?.data?.responseModel?.count}
              currentPage={payload?.data?.data?.responseModel?.pageNumber}
              pageSize={payload?.data?.data?.responseModel?.pageSize}
              onPageChange={onPageChange}
              totalPages={payload?.data?.data?.responseModel?.pages}
            />
          </div>
        )}
      </div>
      <ExportModal show={openExport} toggleModal={()=>setOpenExport(!openExport)}/>
    </div>
  );
};

export default SpendingLog;
