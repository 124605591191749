import React, { useEffect } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router";
import URLS from "helpers/urls";
import Notification from "shared/notifications/notifications";
import ConfigureKyc from "pages/ConfigureKyc/ConfigureKyc";
import KycConfiguration from "pages/KycConfiguration/KycConfiguration";
import ManagerPage from "pages/ManagerPage/ManagerPage";

const App = ({ url, user, isChild }) => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/kyc-service") {
      history.push(URLS.Home);
    } else {
      const path = history.location.pathname.split("/");
      const index = path.indexOf("products");
      const urlPath = path.splice(index, path.length);
      history.push(`/${urlPath.join("/")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid" style={{ minHeight: "65vh" }}>
      <Notification />
      <Switch>
        <Route
          exact
          path={URLS.Configure}
          render={() => <ConfigureKyc isChild={isChild} />}
        />
        <Route
          exact
          path={URLS.ConfigureUpdate}
          render={() => <ConfigureKyc isChild={isChild} />}
        />
        <Route
          exact
          path={URLS.Configuration}
          render={() => <KycConfiguration isChild={isChild} />}
        />
        {/* <Route path={URLS.Home} component={ServiceDetails} /> */}
        <Route path={URLS.Home} component={ManagerPage} />
      </Switch>
    </div>
  );
};

export default App;
