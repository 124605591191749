import React, { useEffect } from "react";
import "./VerifiedLookup.scss";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import URLS from "helpers/urls";
import { useDispatch, useSelector } from "react-redux";
import checkBase64Prefix from "helpers/checkBase64Prefix";
import { errorMessage } from "redux/actions/notification";

const VerifiedLookup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [identityData, setIdentiyData] = React.useState(null);
  const { validateIdentitySuccess, validatingIdentity, validateIdentityError } =
    useSelector(({ kycInstances }) => {
      return {
        validatingIdentity: kycInstances.validatingIdentity,
        validateIdentitySuccess: kycInstances.validateIdentitySuccess,
        validateIdentityError: kycInstances.validateIdentityError,
      };
    });

  const newIdentityData = () => {
    const _data = { ...identityData };
    delete _data.responseCode;
    delete _data.base64Image;

    return _data;
  };

  useEffect(() => {
    if (validateIdentitySuccess && !validatingIdentity) {
      if (validateIdentitySuccess.data?.data) {
        setIdentiyData(validateIdentitySuccess.data?.data?.responseObject);
      } else {
        history.push(URLS.Lookup);
        // dispatch(errorMessage("Unable to verify identity"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="verified-lookup">
      <div className="d-flex my-3">
        <div
          className="d-flex align-items-center head-section__icon d-flex justify-content-center align-items-center"
          onClick={() => {
            history.push(URLS.Lookup);
          }}
        >
          <i className="ri-arrow-drop-left-line"></i> Back to Lookup
        </div>
      </div>
      <div className="d-flex mb-3 justify-content-center w-100 lookup-header">
        <div className="lookup-avatar-container">
          <div className="lookup-avatar shadow-sm">
            <img
              className="w-100 h-100 rounded-circle img-fluid"
              src={checkBase64Prefix(identityData?.base64Image)}
              alt="lookup avatar"
            />
          </div>
          <p className="mb-0 text-center">
            {newIdentityData()?.firstName} {newIdentityData()?.lastName}
          </p>
          <p className="text-primary fw-bold text-center">
            BVN ({newIdentityData()?.bvn})
          </p>
        </div>
      </div>
      <hr className="text-secondary border-2 opacity-50"></hr>
      <Row xs={1} md={3} lg={4} className="g-2">
        {Object.keys(newIdentityData())?.map((identity, index) => (
          <Col key={index}>
            <Card className="lookup-card h-100" outline={true}>
              <CardBody className="d-flex">
                <div>
                  <p className="m-0 fw-bold text-capitalize">
                    {identity.split(/(?=[A-Z])/).join(" ")}
                  </p>
                  <p className="m-0 text-secondary text-capitalize">
                    {newIdentityData()[identity] || "N/A"}
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default VerifiedLookup;
