export const KYC_LIST = [
  "Validate BVN",
  "Lookup BVN",
  "Lookup Driver's License",
  "Lookup VIN",
  "Lookup CAC (Basic)",
  "Lookup NIN",
  "Lookup CAC (Premium)",
  "Lookup Phone Number (Basic)",
  "Lookup TIN",
  "Lookup Phone Number (Advanced)",
  "Age/Identity Verification",
  "Selfie Verification with BVN",
  "Selfie Verification with NIN",
];

export const KycInstances = [
  {
    instanceId: "21032034040",
    name: "Account 1",
    currency: "NGN",
    identity: "BVN",
    status: true,
    status_text: "Enabled",
  },
  {
    instanceId: "21032034041",
    name: "Account 2",
    currency: "NGN",
    identity: "BVN",
    status: true,
    status_text: "Enabled",
  },
  {
    instanceId: "21032034042",
    name: "Account 3",
    currency: "NGN",
    identity: "BVN",
    status: false,
    status_text: "Disabled",
  },
];

export const KycHistoryData = [
  {
    status_text: "Successful",
    amount: 200000,
    identity: "BVN",
    transactionDate: "2021-05-20T14:01:54.9571247Z",
  },
  {
    status_text: "Successful",
    amount: 200000,
    identity: "Int'l Passport",
    transactionDate: "2021-05-20T14:01:54.9571247Z",
  },
  {
    status_text: "Successful",
    amount: 200000,
    identity: "BVN",
    transactionDate: "2021-05-20T14:01:54.9571247Z",
  },
  {
    status_text: "Successful",
    amount: 200000,
    identity: "Int'l Passport",
    transactionDate: "2021-05-20T14:01:54.9571247Z",
  },
];
