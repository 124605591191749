import { configureLookup } from "helpers/urls";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import "./lookupModal.scss";
import {
  fetchSettingsData,
  getIdentityTypesData,
  LoadServiceManagerCountries,
  validateIdentity,
} from "redux/actions/kycInstance";
import { errorMessage, successMessage } from "redux/actions/notification";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object({
  inputValue: yup.string().required("This field is required"),
}).required();

const LookupModal = ({ show, toggleModal, type, countryCode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitStatus, setSubmitStatus] = useState(null);
  const [serverError, setServerError] = useState("");
  const {register, handleSubmit, watch, formState: { errors }} = useForm({
    resolver: yupResolver(schema)
  });

  const inputValue = watch("inputValue")

  useEffect(() => {
    if(inputValue) {
      setServerError("")
    }
  }, [inputValue])

  const {
    validatingIdentity,
    validateIdentitySuccess,
    validatingIdentityData,
    validateIdentityError,
  } = useSelector(({ kycInstances }) => {
    return {
      validatingIdentity: kycInstances.validatingIdentity,
      validateIdentitySuccess: kycInstances.validateIdentitySuccess,
      validateIdentityDataSuccess: kycInstances.validateIdentityDataSuccess,
      validatingIdentityData: kycInstances.validatingIdentityData,
      validateIdentityError: kycInstances.validateIdentityError,
    };
  });

  useEffect(() => {
    dispatch(getIdentityTypesData(type));
  }, [type]);

  useEffect(() => {
    if (validateIdentitySuccess && !validatingIdentity && submitStatus) {
      dispatch(successMessage("Identity Validated Successfully"));
      history.push(configureLookup(type?.toLowerCase()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateIdentitySuccess]);

  useEffect(() => {
    if (
      !validatingIdentity &&
      validateIdentityError?.data?.hasError &&
      submitStatus
    ) {
      setSubmitStatus(false);
      dispatch(
        errorMessage(
          validateIdentityError?.data?.errors[0] ||
            "An error occured while validating identity"
        )
      );
      setServerError(validateIdentityError?.data?.errors[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatingIdentity]);

  const submit = (data, e) => {
    e.preventDefault();
    const key = type?.toLowerCase();
    const payload = {
      [key]: data.inputValue
    }
    setSubmitStatus(true);
    dispatch(validateIdentity(type, countryCode, payload));
  };

  return (
    <Modal isOpen={show} toggle={toggleModal} centered className="main__modal">
      <ModalHeader toggle={toggleModal}>Lookup {type}</ModalHeader>
      <ModalBody>
        {validatingIdentityData ? (
          <>
            <div className="d-flex justify-content-center">
              <Spinner size="sm" color="primary" />
            </div>
          </>
        ) : (
          <form onSubmit={handleSubmit(submit)}>
            <div className="mb-5 form-group">
              <label htmlFor="inputValue" required>{type}</label>
              <input {...register("inputValue")} className={`form-control ${serverError || errors.inputValue?.message ? "is-invalid" : ""}`} />
              <div className="invalid-feedback">
                {serverError || errors.inputValue?.message}
              </div>
            </div>
            <Button color="primary" className="w-100" type="submit" disabled={validatingIdentity}>
            Submit &nbsp;
            {validatingIdentity && <Spinner size="sm" color="white" />}
          </Button>
          </form>
          // <Form
          //   schema={generateFormSchema()}
          //   transformErrors={generateCustomFormErrors}
          //   onSubmit={submit}
          //   liveValidate>
          //   <div>
          //     {validateIdentityDataSuccess?.data?.data.length === 0 && (
          //       <p className="text-center">
          //         Coming soon. please check back later
          //       </p>
          //     )}
          //     <Button color="primary" className="w-100" type="submit">
          //       Submit &nbsp;
          //       {validatingIdentity && <Spinner size="sm" color="white" />}
          //     </Button>
          //   </div>
          // </Form>
        )}
      </ModalBody>
    </Modal>
  );
};

export default LookupModal;
