import BaseOneCalendarContainer from "components/CalendarContainer";
import { format } from "date-fns";
import useDateValidator from "hooks/useDateRangeValidator";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button, Spinner } from "reactstrap";
import { downloadReport } from "redux/actions/kycInstance";
import { errorMessage } from "redux/actions/notification";
import "./exportModal.scss";

const ExportModal = ({ show, toggleModal }) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);
  const dateRangeInvalidMessage = useDateValidator(startDate, endDate);

  const { identityDataLoading, identityDataSuccess, identityDataError } =
    useSelector(({ kycInstances }) => kycInstances);

  const onSubmit = () => {
    setSubmitStatus(true);
    if (startDate && endDate) {
      const start = format(new Date(startDate), "yyyy-MM-dd");
      const end = format(new Date(endDate), "yyyy-MM-dd");
      dispatch(downloadReport(start, end));
    }
  };

  useEffect(() => {
    if (!identityDataLoading && identityDataSuccess) {
      const url = window.URL.createObjectURL(
        new Blob([identityDataSuccess?.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setStartDate(null);
      setEndDate(null);
      setSubmitStatus(false);
      toggleModal();
    } else if (!identityDataLoading && identityDataError) {
      dispatch(
        errorMessage(
          identityDataError?.data?.message || "Error occurred while downloading"
        )
      );
    }
  }, [identityDataLoading]);
  return (
    <Modal
      isOpen={!!show}
      toggle={toggleModal}
      centered
      size="lg"
      className="export-modal-container"
    >
      <ModalHeader toggle={toggleModal}>Export Transactions Log</ModalHeader>
      <ModalBody className="modal__body">
        <div className="mb-3">
          {dateRangeInvalidMessage && (
            <div
              className="alert alert-danger py-1 d-flex align-items-center gap-3"
              role="alert"
            >
              <i className="ri-alert-line text-danger"></i>
              <span className="text-small">{dateRangeInvalidMessage}</span>
            </div>
          )}
        </div>
        <form className="create-form">
          <div className="mb-4">
            <label htmlFor="start">Start Date</label>
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              id="start"
              name="start"
              calendarContainer={BaseOneCalendarContainer}
            />
          </div>
          {submitStatus && !startDate && (
            <div className="error-desc">Start date is required</div>
          )}

          <div>
            <label htmlFor="end">End Date</label>
            <ReactDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
              id="end"
              name="end"
              calendarContainer={BaseOneCalendarContainer}
            />
          </div>
          {submitStatus && !endDate && (
            <div className="error-desc">End date is required</div>
          )}
        </form>

        <div>
          <Button
            color="primary"
            onClick={onSubmit}
            className="submit-btn"
            disabled={identityDataLoading || dateRangeInvalidMessage}
          >
            {!identityDataLoading ? "Download" : <Spinner size="sm" />}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ExportModal;
