import { format } from "date-fns";
import getSymbolFromCurrency from "currency-symbol-map";
import CurrencyFormat from "react-currency-format";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "reactstrap";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import DateRangePicker from "react-daterange-picker";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { ContentLoader } from "components/loader/Loader";
import EmptyState from "components/emptyState/EmptyState";

export const options = (currency) => ({
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += `${currency}${context.parsed.y.toFixed(2)}`
          }
          return label;
        },
      },
    },
    legend: {
      display: false,
      align: "start",
    },
    title: {
      display: false,
      text: "",
      align: "start",
    },
  },
});

const BarChartOverview = ({ loading, dataSource, totalReceived, onCurrencyTabClick, remount, dateRange, renderContent, onSelect, onClear, currencies, selectedCurrency }) => {
  const [data, setData] = useState(null);
  const handleTabItemClick = data => {
    onCurrencyTabClick && onCurrencyTabClick(data);
  };

  useEffect(() => {
    if (dataSource && dataSource.length) {
      const d = dataSource.map(v => v.cost);
      setData({
        labels: dataSource.map(v => v.transactionDate && format(new Date(v.transactionDate), "dd MMM")),
        datasets: [
          {
            label: "",
            data: d,
            hoverBackgroundColor: "#175FFF",
            backgroundColor: "rgba(23, 95, 255, 0.2)",
            maxBarThickness: 25,
          },
        ],
      });
    } else {
      setData(null);
    }
  }, [dataSource]);

  return (
    <Card>
      <div className="sp-p--24 sp-mb--16 w-100 d-flex align-items-center justify-content-between bb-100">
        <Typography typographyVariant="section-title" text="Total Spent" />
        <div className="d-flex align-items-center justify-content-end">
          <FilterDropdown items={currencies} activeItem={selectedCurrency} onItemClick={handleTabItemClick} />
          <div className="ms-4">
            <FilterDropdown activeItem={renderContent(dateRange)}>
              {!remount && <DateRangePicker numberOfCalendars={2} value={dateRange} onSelect={onSelect} className="kyc-reports" />}
              <div className="py-2 px-3">{dateRange && <Button label="Clear Selection" background="blue" className="w-100" onClick={() => onClear && onClear()} />}</div>
            </FilterDropdown>
          </div>
        </div>
      </div>
      <div className="sp-p--24 sp-mb--16">
        <Typography typographyVariant="disp-heading" text={<CurrencyFormat
                        value={totalReceived}
                        displayType="text"
                        thousandSeparator
                        prefix={getSymbolFromCurrency(selectedCurrency)}
                        renderText={value => <span>{value}</span>}
                        decimalScale={2}
                        fixedDecimalScale
                      />} />
      </div>
      {loading ? (
          <ContentLoader height="36vh" />
      ) : (
        <>
          {data ? (
            <Bar options={options(getSymbolFromCurrency(selectedCurrency))} data={data} height="80vh" />
          ) : (
            <div className="d-flex align-items-center justify-content-center w-100">
             <EmptyState />
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default BarChartOverview;
