import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import {
  fetchKyCCountries,
  getValidateIdentityTypes,
} from "redux/actions/kycInstance";
import LookupModal from "../LookupModal/lookupModal";
import "./VerifyLookup.scss";

const VerifyLookup = () => {
  const dispatch = useDispatch();

  const {
    loadingValidateIndentityTypes,
    validateIdentityTypesSuccess,
    kycCountries,
  } = useSelector(({ kycInstances }) => {
    return {
      loadingValidateIndentityTypes: kycInstances.loadingValidateIndentityTypes,
      validateIdentityTypesSuccess: kycInstances.validateIdentityTypesSuccess,
      kycCountries: kycInstances.kycCountries,
    };
  });
  const [showModal, setShowModal] = useState(false);
  const [activeLookup, setActiveLookup] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    dispatch(getValidateIdentityTypes());
    dispatch(fetchKyCCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (kycCountries?.data) {
      const cont = kycCountries?.data[0];
      setSelectedCountry({
        label: cont?.countryCode,
        value: cont?.countryCode,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycCountries?.data]);

  return (
    <div className="lookup-container mt-4">
      <div className="d-flex gap-2 align-items-center">
        <FilterDropdown
          activeItem={selectedCountry}
          items={kycCountries?.data?.map((v) => ({
            label: v.countryCode,
            value: v.countryCode,
          }))}
          onItemClick={(item) => setSelectedCountry(item)}
        />
        <div className="search-container me-3">
          <input
            type="search"
            disabled
            placeholder="search"
            className="input__search"
            onChange={() => {}}
          />
          {true && <i className="ri-search-line"></i>}
        </div>
      </div>
      <div className="mt-4">
        {loadingValidateIndentityTypes ? (
          <div className="d-flex justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <Row xs={1} md={3} lg={4} className="g-2">
            {validateIdentityTypesSuccess?.data?.data.map((item) => (
              <Col key={item}>
                <Card className="lookup-card h-100" outline={true}>
                  <CardBody className="d-flex">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <div>
                        <p className="m-0 fw-bold">
                          Lookup {item.split("_").join(" ")}
                        </p>
                        {item.toLowerCase() !== "bvn" && (
                          <div className="badge bg-secondary text-light">
                            coming soon.
                          </div>
                        )}
                      </div>

                      <Button
                        size="sm"
                        disabled={item.toLowerCase() !== "bvn"}
                        type="button"
                        color="light"
                        onClick={() => {
                          setShowModal(true);
                          setActiveLookup(item);
                        }}
                        className="bg-transparent border-0 text-primary d-flex align-items-center"
                      >
                        View <i className="ri-arrow-right-s-line"></i>
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        )}
        {activeLookup && (
          <LookupModal
            countryCode={selectedCountry?.value}
            show={showModal}
            type={activeLookup}
            toggleModal={() => setShowModal(!showModal)}
          />
        )}
      </div>
    </div>
  );
};

export default VerifyLookup;
