import React from "react";
import Shimmer from "react-shimmer-effect";
import { Spinner } from "reactstrap";
import "./loader.scss";

export default function Loader() {
  return <div>Retriving Authentication State.....</div>;
}

export const ShimmerTableRowLoader = (props) => {
  const { rows, columnWidths = ["100%"], isChild, isGrandChild } = props;
  const rowList = [];
  for (let i = 1; i <= (rows || 5); i++) {
    rowList.push(i);
  }

  return (
    <>
      {rowList.map((row) => (
        <tr
          key={row}
          className={`cl-margin-padding ${isChild ? "child-row" : ""} ${
            isGrandChild ? "grand-child-row" : ""
          }`}
        >
          {columnWidths.map((columnWidth, idx) => (
            <td
              width={`${columnWidth}%`}
              key={`${columnWidth}${idx}`}
              style={{ display: "table-cell" }}
            >
              <Shimmer>
                <div className="shimmer-line" data-testid="td-line-loader" />
              </Shimmer>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export const LoadingTableContent = ({
  colSpan,
  rows,
  isChild,
  isGrandChild,
}) => {
  return (
    <ShimmerTableRowLoader
      rows={rows || 10}
      columnWidths={new Array(parseInt(colSpan)).fill(100 / colSpan)}
      isChild={isChild}
      isGrandChild={isGrandChild}
      // columnWidths={new Array(parseInt(colSpan)).fill(0).map(val => "100%")}
    />
  );
};

export const LoadingDropdownItems = ({ name }) => {
  return (
    <span className="loading-dropdown">
      Loading {name} <i className="fa fa-spin fa-spinner"></i>
    </span>
  );
};

export const LineLoader = ({ lines = 1, className, ...rest }) => {
  const lineNum = [];
  for (let i = 1; i <= lines; i++) {
    lineNum.push(i);
  }
  return (
    <>
      {lineNum.map((n) => (
        <Shimmer key={n}>
          <div
            className={`shimmer-line ${className}`}
            data-testid="line-loader"
            {...rest}
          />
        </Shimmer>
      ))}
    </>
  );
};

export const ContentLoader = ({ width, height, grow }) => (
  <div
    style={{
      height: height || "75vh",
      width: width || "75vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Spinner color="primary">
      Loading...
    </Spinner>
  </div>
);
