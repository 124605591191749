import URLS from "helpers/urls";
import React from "react";
import { Route, Switch } from "react-router-dom";
import VerifiedLookup from "./VerifiedLookup/VerifiedLookup";
import VerifyLookup from "./VerifyLookup/VerifyLookup";

const LookupPage = () => {
  return (
    <>
      <Switch>
        <Route exact path={URLS.Lookup} component={VerifyLookup} />
        <Route path={URLS.LookupDetails} component={VerifiedLookup} />
      </Switch>
    </>
  );
};

export default LookupPage;
