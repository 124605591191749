import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import "./alertModal.scss";

const AlertModal = ({ show, toggleModal, onCancel, data }) => {
  return (
    <Modal
      isOpen={!!show}
      toggle={toggleModal}
      centered
      size="lg"
      className="alert__modal"
    >
      <ModalHeader
        toggle={toggleModal}
        className="font-weight-bold title"
      ></ModalHeader>
      <ModalBody className="modal__body d-flex align-items-center justify-content-between gap-5 flex-column">
        <div className="d-flex align-items-center justify-content-center">
          <div
            className={`${data?.data?.hasError || data?.data?.responseCode !== 'OK' ? "error" : "success"
              }__icon d-flex align-items-center justify-content-center`}
          >
            <i
              className={`ri-${data?.data?.hasError || data?.data?.responseCode !== 'OK' ? "close" : "check"
                }-line ri-3x`}
            ></i>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div
            className={`font-weight-bold f-11 text-${data?.data?.hasError || data?.data?.responseCode !== 'OK' ? "error" : "success"
              }`}
          >
            {data?.data?.hasError || data?.data?.responseCode !== 'OK'
              ? data?.data?.responseMessage ||
              "Something went wrong while trying to onboard merchant"
              : `Your onboarding ${data?.data?.responseData?.providerName
                ? "on" + data?.data?.responseData?.providerName
                : ""
              } has been initiated.`}
          </div>
          <div className="msg-wrapper">
            <i
              className={`ri-${data?.data?.hasError || data?.data?.responseCode !== 'OK'
                ? "close-line text-danger"
                : "check-line text-success"
                }`}
            ></i>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-100 d-flex align-items-center justify-content-center gap-2">
          <Button
            label="Close"
            background="blue"
            size="md"
            fullWidth
            variant="primary"
            onClick={() => onCancel()}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AlertModal;
