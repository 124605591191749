import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";
import ServiceDetails from "pages/ServiceDetails/ServiceDetails";
import { checkMerchantStatus } from "redux/actions/kycInstance";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import CustomSpinner from "shared/CustomSpinner/CustomSpinner";
import { errorMessage, successMessage } from "redux/actions/notification";

const ManagerPage = () => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(true);
  const [alreadyOnboarded, setAlreadyOnboarded] = useState(true);

  const { checkingMerchantStatus, merchantStatusError, merchantStatus } =
    useSelector(({ kycInstances }) => kycInstances);

  useEffect(() => {
    dispatch(checkMerchantStatus());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!checkingMerchantStatus && merchantStatus && checked) {
      setChecked(false);
      !alreadyOnboarded &&
        dispatch(
          successMessage(
            merchantStatus?.data?.responseMessage || `Verification complete. `
          )
        );
    }
    if (!checkingMerchantStatus && merchantStatusError && checked) {
      setChecked(false);
      dispatch(
        errorMessage(
          merchantStatusError?.message ||
            "Unable to verify your onboarding status on this service"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkingMerchantStatus, merchantStatusError, merchantStatus]);

  const renderConditionalContent = (onboarded, active) => {
    return onboarded && active ? (
      <ServiceDetails />
    ) : (
      <OnboardingPage
        onOnboardClicked={() => setAlreadyOnboarded(false)}
        isOnboarded={onboarded}
      />
    );
  };

  if (checkingMerchantStatus || !merchantStatus) {
    return <CustomSpinner height="75vh" />;
  }

  if (merchantStatus?.data && !merchantStatus?.data.hasError) {
    return renderConditionalContent(
      merchantStatus?.data?.data?.isOnboarded,
      merchantStatus?.data?.data?.isOnboardedAndActive
    );
  }
  if (merchantStatus?.data && merchantStatus?.data.hasError) {
    return <ErrorPage />;
  }
  return <OnboardingPage onOnboardClicked={() => setAlreadyOnboarded(false)} />;
};

export default ManagerPage;
