import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import "./confirmationModal.scss";

const ConfirmationModal = ({ show, toggleModal, onConfirm }) => {
  return (
    <Modal
      isOpen={!!show}
      toggle={toggleModal}
      centered
      size="lg"
      className="main__modal"
    >
      <ModalHeader
        toggle={toggleModal}
        className="font-weight-bold title"
      ></ModalHeader>
      <ModalBody className="modal__body d-flex align-items-center justify-content-center gap-2 flex-column">
        <div className="d-flex align-items-center justify-content-center">
          <div className="alert__icon d-flex align-items-center justify-content-center">
            <i className="ri-error-warning-line ri-3x"></i>
          </div>
        </div>
        <p className="notice">
          This action will onboard you into the KYC service. Click OKAY if you
          agree with the terms and conditions of the service.
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="w-100 d-flex align-items-center justify-content-center gap-2">
          <Button
            label="Cancel"
            background="neutral"
            variant="outline"
            fullWidth
            size="md"
            onClick={() => onConfirm()}
          />
          <Button
            label="Okay"
            background="blue"
            size="md"
            variant="primary"
            fullWidth
            onClick={() => onConfirm(true)}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
