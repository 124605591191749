import { getUserDetails } from "apiServices/user.service";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import { checkMerchantStatus, requestAccess } from "redux/actions/kycInstance";
import "./onboardingPage.scss";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import AlertModal from "components/AlertModal/AlertModal";

function OnboardingPage({ isOnboarded, onOnboardClicked }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();
  const { requestingAccess, requestAccessPayload, requestAccessError } =
    useSelector(({ kycInstances }) => kycInstances);
  const { Client_Identifier } = getUserDetails()?.accessToken
    ? jwt_decode(getUserDetails()?.accessToken)
    : {};
  let [submitStatus, setSubmitStatus] = useState(false);
  let [disabled, setDisabled] = useState(false);
  let [message, setMessage] = useState(null);

  const handleRequestAccess = () => {
    onOnboardClicked();
    setShowConfirm(true);
  };

  useEffect(() => {
    if (
      !requestingAccess &&
      (requestAccessPayload || requestAccessError) &&
      submitStatus
    ) {
      setSubmitStatus(false);
      if (!(requestAccessPayload?.data?.hasError || requestAccessPayload?.data?.responseCode !== 'OK')) {
        setDisabled(true);
      }
      setMessage(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestingAccess]);

  const handleConfirm = (confirmed) => {
    setShowConfirm(false);
    if (confirmed) {
      setSubmitStatus(true);
      dispatch(requestAccess({ merchantId: Client_Identifier }));
    }
  };

  const handleClose = () => {
    setMessage(false);
    dispatch(checkMerchantStatus());
  };

  return (
    <div className="onboard-wrapper">
      <section className="head-section d-flex sp-mb--24">
        <div
          className="head-section__icon d-flex justify-content-center align-items-center"
          onClick={() => {
            window.location.replace("/products");
          }}
        >
          <i className="ri-arrow-drop-left-line"></i> KYC
        </div>
      </section>
      <section className="card-section sp-pt--24 sp-pb--24 sp-pl--32 sp-pr--32">
        <div className="sp-mb--16">
          <Typography
            typographyVariant="section-title"
            text="Onboard on KYC service in minutes."
          />
        </div>
        <div className="w-sm-100 w-75">
          <p className="content-paragraph">
            With the BaseOne KYC-as-a-service module, you can carry out KYC
            verification and validation on your customers in your application
            and transactions on the BaseOne platform. We offer a straightforward
            approach to doing KYC and interactive APIs to integrate the KYC
            feature in your application. We have licenses that cover your KYC
            operations when you use BaseOne KYC.
          </p>
        </div>
        <div>
          <Button
            label={
              requestingAccess
                ? "Requesting..."
                : isOnboarded
                  ? "Pending Approval"
                  : "Request Access"
            }
            background="blue"
            disabled={isOnboarded || disabled || requestingAccess}
            size="md"
            onClick={handleRequestAccess}
          />
          <Button
            label={
              <span className="d-flex align-items-center">
                <span>Learn More</span>
                <i className="ri-arrow-right-up-line"></i>
              </span>
            }
            onClick={() =>
              window.open("https://developer.baseone.co/docs/kyc/", "_blank")
            }
            background="neutral"
            size="md"
            className="learn-more-btn"
          />
        </div>
        <p className="footnote">
          Note: BaseOne empowers you to onboard KYC for your users and
          customers.
        </p>
      </section>
      <ConfirmationModal show={showConfirm} onConfirm={handleConfirm} />
      <AlertModal
        show={message}
        data={requestAccessPayload}
        onCancel={handleClose}
      />
    </div>
  );
}

export default OnboardingPage;
