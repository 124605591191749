import React, { useEffect, useMemo, useState } from "react";
import _debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { format } from "date-fns";
import { Typography, Button, PaginationWrapper, Pagination, PaginationIndicator, Table } from "vgg-ui-react/dist/components/Core";

import EmptyState from "components/emptyState/EmptyState";
import { LineLoader, LoadingTableContent } from "components/loader/Loader";

import { LoadKycHistory, LoadKycSummary } from "redux/actions/kycInstance";
import { getCurrencyValue } from "helpers/currencyMarkup";
import URLS, { ConfigureUpdate } from "helpers/urls";
import "./kycConfiguration.scss";

const KycConfiguration = ({ isChild }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { loadingKycHistory, kycHistory, kycSummary, loadingKycSummary } = useSelector(({ kycInstances }) => kycInstances);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const instance = useMemo(() => history?.location?.state?.data, [history?.location?.state?.data]);

  useEffect(() => {
    dispatch(LoadKycSummary({ instanceId: params?.id }));
    dispatch(LoadKycHistory({ instanceId: params?.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      LoadKycHistory({
        pageNo: paging.pageNumber,
        pageSize: paging.pageSize,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  const onEditConfiguration = (event, rowData) => {
    const { id } = params;
    history.push({
      pathname: ConfigureUpdate(id),
      state: { data: history?.location?.state?.data },
    });
  };

  const handleSearch = _debounce(event => {
    dispatch(
      LoadKycHistory({
        searchBy: event.target.value,
        pageNo: paging.pageNumber,
        pageSize: paging.pageSize,
      })
    );
  }, 1000);

  const onPageClick = evt => {
    setPaging(prev => ({ ...prev, pageNumber: evt }));
  };

  return (
    <div className="kyc-configuration">
      <div className="pg-header d-flex align-items-center justify-content-between sp-pb--32 bb-200">
        <div className="d-flex align-items-center">
          <span
            className="back-btn"
            onClick={() =>
              history.push({
                pathname: `${isChild ? URLS.KycService : "/"}`,
                state: { actionType: "configure" },
              })
            }
          >
            <i className="ri-arrow-left-s-line ri-2x"></i>
          </span>
          <Typography typographyVariant="disp-heading" text="Account 1" className="font-weight-bold" />
        </div>
        <div className="w-150">
          <Button label="Edit" background="blue" size="sm" className="w-100" onClick={onEditConfiguration} />
        </div>
      </div>
      <div className="content-container">
        <div className="content-right p-3">
          <div className="pg-header sp-pt--48">
            <Typography typographyVariant="section-title" text="General Information" className="font-weight-bold" />
          </div>
          <div className="sp-pt--16">
            <div className="summary">
              <div className="kyc">
                <div className="d-flex align-items-center justify-content-between">
                  <Typography typographyVariant="caption" text="Kyc Wallet" className="font-weight-bold" />
                  <div className="icon d-flex justify-content-center align-items-center">
                    <i className="ri-money-dollar-circle-line"></i>
                  </div>
                </div>
                <Typography
                  typographyVariant="disp-heading"
                  text={
                    loadingKycSummary ? (
                      <div className="w-25">
                        <LineLoader />
                      </div>
                    ) : (
                      getCurrencyValue(instance?.currency?.toLowerCase(), kycSummary?.value)
                    )
                  }
                  className="font-weight-bold"
                />
              </div>
              <div className="users">
                <div className="d-flex align-items-center justify-content-between">
                  <Typography typographyVariant="caption" text="Total Counts" className="font-weight-bold" />
                  <div className="icon d-flex justify-content-center align-items-center">
                    <i className="ri-user-line"></i>
                  </div>
                </div>
                <Typography
                  typographyVariant="disp-heading"
                  text={
                    loadingKycSummary ? (
                      <div className="w-25">
                        <LineLoader />
                      </div>
                    ) : (
                      kycSummary?.kycCount || "--"
                    )
                  }
                  className="font-weight-bold"
                />
              </div>
              <div className="currency">
                <div className="d-flex align-items-center justify-content-between">
                  <Typography typographyVariant="caption" text="Currency" className="font-weight-bold" />
                  <div className="icon d-flex justify-content-center align-items-center">
                    <i className="ri-money-dollar-circle-line"></i>
                  </div>
                </div>
                <Typography
                  typographyVariant="disp-heading"
                  text={
                    loadingKycSummary ? (
                      <div className="w-25">
                        <LineLoader />
                      </div>
                    ) : (
                      kycSummary?.currency || "--"
                    )
                  }
                  className="font-weight-bold"
                />
              </div>
            </div>
          </div>

          <div className="sp-pt--40">
            <div className="d-flex align-items-center justify-content-between">
              <Typography typographyVariant="section-title" text="Kyc History" className="font-weight-bold" />
            </div>
            <div className="sp-pt--24 sp-pb--16 d-flex align-items-center justify-content-between">
              <div className="search-container">
                <input type="text" placeholder="Search" className="form-control" onChange={handleSearch} />
                <i className="ri-search-line"></i>
              </div>
            </div>

            {loadingKycHistory ? (
              <Table variant="" className="table-striped ">
                <thead>
                  <tr>
                    <th width="15%">Transaction</th>
                    <th width="25%">Card</th>
                    <th width="25%">Amount</th>
                    <th width="15%">Date</th>
                    <th width="15%">Time</th>
                  </tr>
                </thead>
                <tbody className="no-border-top">
                  <LoadingTableContent colSpan={5} />
                </tbody>
              </Table>
            ) : (
              <>
                {kycHistory?.items?.length ? (
                  <>
                    <Table variant="unBounded" className="table-striped ">
                      <thead>
                        <tr>
                          <th width="15%">Status</th>
                          <th width="25%">Amount</th>
                          <th width="25%">Identity Type</th>
                          <th width="15%">Date</th>
                          <th width="15%">Time</th>
                        </tr>
                      </thead>
                      <tbody className="no-border-top">
                        {kycHistory?.items?.map((app, idx) => (
                          <tr key={app.instanceId}>
                            <td>{app?.status_text || "--"}</td>
                            <td>{getCurrencyValue(instance?.currency?.toLowerCase(), app?.amount)}</td>
                            <td>{app?.identity || "--"}</td>
                            <td>{app?.transactionDate ? format(new Date(app?.transactionDate), "dd MMMM, yyyy") : "--"}</td>
                            <td>{app?.transactionDate ? format(new Date(app?.transactionDate), "h:MM b") : "--"}</td>
                          </tr>
                        ))}{" "}
                      </tbody>
                    </Table>
                    <div>
                      <PaginationWrapper>
                        <PaginationIndicator currentPage={kycHistory?.pageNumber || 1} totalPages={kycHistory?.pages || 1} />
                        <div className="pagination-container">
                          <Pagination
                            className="pagination-bar"
                            currentPage={paging?.pageNumber}
                            totalCount={kycHistory?.items?.length}
                            pageSize={paging?.pageSize}
                            onPageChange={page => onPageClick(page)}
                          />
                        </div>
                      </PaginationWrapper>
                    </div>
                  </>
                ) : (
                  <EmptyState />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KycConfiguration;
