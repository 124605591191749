export const URLS = {
  Home: "/products/kyc",
  Configure: "/products/kyc/configure-kyc",
  ConfigureUpdate: "/products/kyc/configure-kyc/:id",
  Configuration: "/products/kyc/kyc-configuration/:id",
  KycService: "/products/kyc/kyc-services",
  Overview: "/products/kyc/kyc-overview",
  Lookup: "/products/kyc/kyc-lookup",
  LookupDetails: "/products/kyc/kyc-lookup/:lookupType",
  Settings: "/products/kyc/kyc-settings",
  KycInstances: "/products/kyc/kyc-instances",
};

export const configureLookup = (type) => {
  return URLS.LookupDetails.replace(":lookupType", type);
};

export const ConfigureUpdate = (id) => {
  return URLS.ConfigureUpdate.replace(":id", id);
};

export const ViewConfiguration = (id) => {
  return URLS.Configuration.replace(":id", id);
};

export default URLS;
