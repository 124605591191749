import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { errorMessage, successMessage } from "redux/actions/notification";
import { fetchSettingsData, updateSettingsData } from "redux/actions/kycInstance";
import CustomSpinner from "shared/CustomSpinner/CustomSpinner";
import Button from "vgg-ui-react/dist/components/Core/Button";

const Settings = ({fetchingCurrencies, currencies}) => {
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();
  const { updatingSettingsData, updateSettingsDataPayload, updateSettingsDataError, loadingSettingsData, settingsData } = useSelector(
    ({ kycInstances }) => kycInstances
  );

  useEffect(() => {
    dispatch(fetchSettingsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!updatingSettingsData && updateSettingsDataPayload && submitStatus) {
      setSubmitStatus(false);
      setShowForm(false)
      dispatch(fetchSettingsData());
      dispatch(successMessage("Instance configuration successful"));
    }
    if (!updatingSettingsData && updateSettingsDataError && submitStatus) {
      setSubmitStatus(false);
      dispatch(errorMessage(updateSettingsDataError?.message || "Unable to update default currency"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSettingsDataPayload, updatingSettingsData]);

  useEffect(() => {
    if (settingsData?.data && currencies) {
      const currency = currencies?.find(currency => currency.currency === settingsData?.data[0]?.currency);
      if (currency) {
        const value = currency.currency;
        const label = currency.currency;
        setSelectedCurrency({ label, value });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsData, currencies]);

  const submit = () => {
    if (selectedCurrency) {
      dispatch(updateSettingsData({ currency: selectedCurrency.value }));
      setSubmitStatus(true);
    }
  };

  return (
    <div className="row fade_in">
      <div className="col-12 col-md-4">
        <p className="fs-5 fw-bold">Preferences</p>
        {loadingSettingsData ? (
          <CustomSpinner height="5vh" />
        ) : (
          <div className="row align-items-start align-items-md-center fade_in">
            {showForm ? (
              <>
                <div className="col-12 col-md-8 mb-3 mb-md-0 fade_in">
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Select Currency"
                    isSearchable
                    isLoading={fetchingCurrencies}
                    value={selectedCurrency}
                    options={currencies.map(currency => ({ label: currency.currency, value: currency.currency }))}
                    onChange={e => setSelectedCurrency(e)}
                  />
                </div>
                <div className="col-12 col-md-4 fade_in">
                  <Button label={updatingSettingsData ? "Saving..." : "Save"} disabled={!selectedCurrency || updatingSettingsData} background="blue" onClick={() => submit()} />
                </div>
              </>
            ) : (
              <div className="col-12 fade_in">
                <p className="text-secondary">
                  Your default currency is <span className={settingsData?.data[0]?.currency ? "font-weight-bold" : ""}>{settingsData?.data[0]?.currency || "not yet set"}</span>
                </p>
                <Button label={settingsData?.data[0]?.currency ? "Change" : "Setup"} background="blue" onClick={() => setShowForm(true)} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
