import { KycHistoryData, KycInstances } from "helpers/kycList";
import { instanceActionTypes } from "../actions/kycInstance";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INITIAL_STATE = {
  data: [],
  loadingData: false,
  dataError: null,

  countries: null,
  loadingCountries: false,
  countriesError: null,

  currencies: [],
  loadingCurrencies: false,
  currenciesError: null,

  kycHistory: [],
  loadingKycHistory: false,
  kycHistoryError: null,

  accounts: [],
  loadingAccounts: false,
  accountsError: null,

  creatingInstance: false,
  createInstanceSuccess: false,
  createInstanceError: null,

  editingInstance: false,
  editInstanceSuccess: false,
  editInstanceError: null,

  loadingKycSummary: false,
  kycSummary: {
    value: "200,567.90",
    currency: "NGN",
    kycCount: 2589,
  },
  kycSummaryError: null,

  switchingStatus: false,
  switchStatusSuccess: null,
  switchStatusError: null,

  loadingCountryKYCs: false,
  countryKYCs: { responseData: [] },
  countryKYCsError: null,

  loadingValidateIndentityTypes: false,
  validateIdentityTypesSuccess: { responseData: [] },
  validateIdentityTypesError: null,

  validatingIdentity: false,
  validateIdentitySuccess: { responseData: [] },
  validateIdentityError: null,
};

const kycInstanceReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(instanceActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        checkingMerchantStatus: true,
        merchantStatus: null,
        merchantStatusError: null,
      };
    case SUCCESS(instanceActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        checkingMerchantStatus: false,
        merchantStatus: actions.payload,
        merchantStatusError: null,
      };
    case FAILURE(instanceActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        merchantStatus: null,
        checkingMerchantStatus: false,
        merchantStatusError: actions.payload,
      };
    case REQUEST(instanceActionTypes.GET_INSTANCES):
      return {
        ...state,
        data: [],
        dataError: null,
        loadingData: true,
      };
    case SUCCESS(instanceActionTypes.GET_INSTANCES):
      return {
        ...state,
        loadingData: false,
        data: actions?.payload?.data?.data,
      };
    case FAILURE(instanceActionTypes.GET_INSTANCES):
      return {
        ...state,
        data: { items: KycInstances },
        dataError: actions?.payload,
        loadingData: false,
      };

    case REQUEST(instanceActionTypes.GET_COUNTRY_KYCS):
      return {
        ...state,
        countryKYCs: { responseData: [] },
        countryKYCsError: null,
        loadingCountryKYCs: true,
      };
    case SUCCESS(instanceActionTypes.GET_COUNTRY_KYCS):
      return {
        ...state,
        loadingCountryKYCs: false,
        countryKYCs: actions?.payload?.data,
      };
    case FAILURE(instanceActionTypes.GET_COUNTRY_KYCS):
      return {
        ...state,
        countryKYCs: { responseData: [] },
        countryKYCsError: actions?.payload,
        loadingCountryKYCs: false,
      };

    case REQUEST(instanceActionTypes.FETCH_KYC_COUNTRIES):
      return {
        ...state,
        kycCountries: { responseData: [] },
        kycCountriesError: null,
        fetchingKycCountries: true,
      };
    case SUCCESS(instanceActionTypes.FETCH_KYC_COUNTRIES):
      return {
        ...state,
        fetchingKycCountries: false,
        kycCountries: actions?.payload?.data,
      };
    case FAILURE(instanceActionTypes.FETCH_KYC_COUNTRIES):
      return {
        ...state,
        kycCountries: { responseData: [] },
        kycCountriesError: actions?.payload,
        fetchingKycCountries: false,
      };

    case REQUEST(instanceActionTypes.FETCH_SETTINGS_DATA):
      return {
        ...state,
        loadingSettingsData: true,
        settingsData: null,
        settingsDataError: null,
      };
    case SUCCESS(instanceActionTypes.FETCH_SETTINGS_DATA):
      return {
        ...state,
        loadingSettingsData: false,
        settingsData: actions?.payload?.data,
        settingsDataError: null,
      };
    case FAILURE(instanceActionTypes.FETCH_SETTINGS_DATA):
      return {
        ...state,
        loadingSettingsData: false,
        settingsData: null,
        settingsDataError: actions?.payload,
      };

    case REQUEST(instanceActionTypes.UPDATE_SETTINGS_DATA):
      return {
        ...state,
        updatingSettingsData: true,
        updateSettingsDataPayload: null,
        updateSettingsDataError: null,
      };
    case SUCCESS(instanceActionTypes.UPDATE_SETTINGS_DATA):
      return {
        ...state,
        updatingSettingsData: false,
        updateSettingsDataPayload: actions?.payload?.data,
        updateSettingsDataError: null,
      };
    case FAILURE(instanceActionTypes.UPDATE_SETTINGS_DATA):
      return {
        ...state,
        updateSettingsDataError: actions?.payload,
        updateSettingsDataPayload: null,
        updatingSettingsData: false,
      };

    case REQUEST(instanceActionTypes.GET_COUNTRIES):
      return {
        ...state,
        countries: null,
        countriesError: null,
        loadingCountries: true,
      };
    case SUCCESS(instanceActionTypes.GET_COUNTRIES):
      return {
        ...state,
        loadingCountries: false,
        countries: actions?.payload?.data?.responseData,
      };

    case FAILURE(instanceActionTypes.GET_COUNTRIES):
      return {
        ...state,
        countriesError: actions?.payload,
        loadingCountries: false,
      };

    case REQUEST(instanceActionTypes.GET_CURRENCIES):
      return {
        ...state,
        currencies: [],
        currenciesError: null,
        loadingCurrencies: true,
      };
    case SUCCESS(instanceActionTypes.GET_CURRENCIES):
      return {
        ...state,
        loadingCurrencies: false,
        currencies: actions?.payload?.data?.responseData,
      };

    case FAILURE(instanceActionTypes.GET_CURRENCIES):
      return {
        ...state,
        currenciesError: actions?.payload,
        loadingCurrencies: false,
      };

    case REQUEST(instanceActionTypes.CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: true,
        createInstanceSuccess: false,
        createInstanceError: null,
      };
    case SUCCESS(instanceActionTypes.CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: false,
        createInstanceSuccess: true,
        createInstanceError: null,
      };

    case FAILURE(instanceActionTypes.CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: false,
        createInstanceSuccess: false,
        createInstanceError: actions?.payload?.data,
      };

    case REQUEST(instanceActionTypes.RESET_CREATE_INSTANCE):
      return {
        ...state,
        creatingInstance: false,
        createInstanceSuccess: false,
        createInstanceError: null,
      };

    case REQUEST(instanceActionTypes.GET_KYC_SUMMARY):
      return {
        ...state,
        loadingKycSummary: true,
        kycSummaryError: null,
      };
    case SUCCESS(instanceActionTypes.GET_KYC_SUMMARY):
      return {
        ...state,
        loadingKycSummary: false,
        kycSummary: actions?.payload?.data,
        kycSummaryError: null,
      };

    case FAILURE(instanceActionTypes.GET_KYC_SUMMARY):
      return {
        ...state,
        loadingKycSummary: false,
        kycSummaryError: actions?.payload?.data,
      };

    case REQUEST(instanceActionTypes.GET_KYC_HISTORY):
      return {
        ...state,
        loadingKycHistory: true,
        kycHistory: [],
        kycHistoryError: null,
      };
    case SUCCESS(instanceActionTypes.GET_KYC_HISTORY):
      return {
        ...state,
        loadingKycHistory: false,
        kycHistory: actions?.payload?.data,
        kycHistoryError: null,
      };

    case FAILURE(instanceActionTypes.GET_KYC_HISTORY):
      return {
        ...state,
        kycHistory: { items: KycHistoryData },
        kycHistoryError: actions?.payload,
        loadingKycHistory: false,
      };

    case REQUEST(instanceActionTypes.GET_ACCOUNTS):
      return {
        ...state,
        loadingAccounts: true,
        accounts: [],
        accountsError: null,
      };
    case SUCCESS(instanceActionTypes.GET_ACCOUNTS):
      return {
        ...state,
        loadingAccounts: false,
        accounts: actions?.payload?.data,
        accountsError: null,
      };

    case FAILURE(instanceActionTypes.GET_ACCOUNTS):
      return {
        ...state,
        accountsError: actions?.payload,
        loadingAccounts: false,
      };

    case REQUEST(instanceActionTypes.GET_CHART_DATA):
      return {
        ...state,
        loadingWalletChart: true,
        walletChart: [],
        walletChartError: null,
      };
    case SUCCESS(instanceActionTypes.GET_CHART_DATA):
      return {
        ...state,
        loadingWalletChart: false,
        walletChart: converChart(actions?.payload?.data),
        walletChartError: null,
      };

    case FAILURE(instanceActionTypes.GET_CHART_DATA):
      return {
        ...state,
        walletChartError: actions?.payload,
        loadingWalletChart: false,
      };

    case REQUEST(instanceActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchingStatus: true,
        switchStatusSuccess: null,
        switchStatusError: null,
      };
    case SUCCESS(instanceActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchingStatus: false,
        switchStatusSuccess: actions?.payload,
        switchStatusError: null,
      };

    case FAILURE(instanceActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchStatusSuccess: null,
        switchStatusError: actions?.payload,
        switchingStatus: false,
      };
    case REQUEST(instanceActionTypes.GET_VALIDATE_IDENTITY_TYPES):
      return {
        ...state,
        loadingValidateIndentityTypes: true,
        validateIdentityTypesSuccess: null,
        validateIdentityTypesError: null,
      };
    case SUCCESS(instanceActionTypes.GET_VALIDATE_IDENTITY_TYPES):
      return {
        ...state,
        loadingValidateIndentityTypes: false,
        validateIdentityTypesSuccess: actions.payload,
        validateIdentityTypesError: null,
      };
    case FAILURE(instanceActionTypes.GET_VALIDATE_IDENTITY_TYPES):
      return {
        ...state,
        loadingValidateIndentityTypes: false,
        validateIdentityTypesSuccess: null,
        validateIdentityTypesError: actions.payload,
      };
    case REQUEST(instanceActionTypes.VALIDATE_IDENTITY):
      return {
        ...state,
        validatingIdentity: true,
        validateIdentitySuccess: null,
        validateIdentityError: null,
      };
    case SUCCESS(instanceActionTypes.VALIDATE_IDENTITY):
      return {
        ...state,
        validatingIdentity: false,
        validateIdentitySuccess: actions.payload,
        validateIdentityError: null,
      };
    case FAILURE(instanceActionTypes.VALIDATE_IDENTITY):
      return {
        ...state,
        validatingIdentity: false,
        validateIdentitySuccess: null,
        validateIdentityError: actions.payload,
      };
    case REQUEST(instanceActionTypes.VALIDATE_IDENTITY_DATA):
      return {
        ...state,
        validatingIdentityData: true,
        validateIdentityDataSuccess: null,
        validateIdentityDataError: null,
      };
    case SUCCESS(instanceActionTypes.VALIDATE_IDENTITY_DATA):
      return {
        ...state,
        validatingIdentityData: false,
        validateIdentityDataSuccess: actions.payload,
        validateIdentityDataError: null,
      };
    case FAILURE(instanceActionTypes.VALIDATE_IDENTITY_DATA):
      return {
        ...state,
        validatingIdentityData: false,
        validateIdentityDataSuccess: null,
        validateIdentityDataError: actions.payload,
      };
    case REQUEST(instanceActionTypes.DOWNLOAD_REPORT):
      return {
        ...state,
        identityDataLoading: true,
        identityDataSuccess: null,
        identityDataError: null,
      };
    case SUCCESS(instanceActionTypes.DOWNLOAD_REPORT):
      return {
        ...state,
        identityDataLoading: false,
        identityDataSuccess: actions.payload,
        identityDataError: null,
      };
    case FAILURE(instanceActionTypes.DOWNLOAD_REPORT):
      return {
        ...state,
        identityDataLoading: false,
        identityDataSuccess: null,
        identityDataError: actions.payload,
      };

    case REQUEST(instanceActionTypes.API_LOGS):
      return {
        ...state,
        apiLogsLoading: true,
        apiLogsPayload: null,
        apiLogsError: null,
      };
    case SUCCESS(instanceActionTypes.API_LOGS):
      return {
        ...state,
        apiLogsLoading: false,
        apiLogsPayload: actions?.payload,
        apiLogsError: null,
      };

    case FAILURE(instanceActionTypes.API_LOGS):
      return {
        ...state,
        apiLogsPayload: null,
        apiLogsError: actions?.payload,
        apiLogsLoading: false,
      };

    case REQUEST(instanceActionTypes.COSTS):
      return {
        ...state,
        costsLoading: true,
        costsPayload: null,
        costsError: null,
      };
    case SUCCESS(instanceActionTypes.COSTS):
      return {
        ...state,
        costsLoading: false,
        costsPayload: actions?.payload,
        costsError: null,
      };

    case FAILURE(instanceActionTypes.COSTS):
      return {
        ...state,
        costsPayload: null,
        costsError: actions?.payload,
        costsLoading: false,
      };

    case REQUEST(instanceActionTypes.REQUEST_ACCESS):
      return {
        ...state,
        requestingAccess: true,
        requestAccessPayload: null,
        requestAccessError: null,
      };
    case SUCCESS(instanceActionTypes.REQUEST_ACCESS):
      return {
        ...state,
        requestingAccess: false,
        requestAccessPayload: actions?.payload,
        requestAccessError: null,
      };

    case FAILURE(instanceActionTypes.REQUEST_ACCESS):
      return {
        ...state,
        requestAccessPayload: null,
        requestAccessError: actions?.payload,
        requestingAccess: false,
      };
    case REQUEST(instanceActionTypes.FETCH_SUPPORTED_CURRENCIES):
      return {
        ...state,
        fetchingCurrencies: true,
        currenciesPayload: null,
        currenciesError: null,
      };
    case SUCCESS(instanceActionTypes.FETCH_SUPPORTED_CURRENCIES):
      return {
        ...state,
        fetchingCurrencies: false,
        currenciesPayload: actions?.payload,
        currenciesError: null,
      };

    case FAILURE(instanceActionTypes.FETCH_SUPPORTED_CURRENCIES):
      return {
        ...state,
        currenciesPayload: null,
        currenciesError: actions?.payload,
        fetchingCurrencies: false,
      };

    default:
      return { ...state };
  }
};

export default kycInstanceReducer;

function converChart(data) {
  const xLabel = [];
  const credited = [];
  const debited = [];

  for (let item of data) {
    xLabel.push(item.transactionDate);
    credited.push(item.credited);
    debited.push(item.debited);
  }
  return {
    labels: xLabel,
    datasets: [
      {
        label: "Credited",
        data: credited,
        fill: true,
        backgroundColor: "rgba(23, 95, 255, 0.1)",
        borderColor: "#175FFF",
      },
      {
        label: "Debited",
        data: debited,
        fill: true,
        backgroundColor: "rgba(255, 105, 105, 0.05)",
        borderColor: "#FF6969",
      },
    ],
  };
}
