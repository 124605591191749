import React, { useEffect } from "react";
import "./service-details.scss";
import CustomTab from "components/CustomTab/CustomTab";
import URLS from "helpers/urls";
import Overview from "pages/Overview/Overview";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Settings from "pages/Settings/Settings";
import LookupPage from "pages/LookupPage/LookupPage";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupportedCurrencies } from "redux/actions/kycInstance";
import KycInstances from "components/KycInstances/KycInstances";

const tabItems = [
  {
    id: 1,
    title: "Overview",
    key: "overview",
    routeLink: URLS.Overview,
    component: Overview,
  },
  {
    id: 2,
    title: "Lookup",
    key: "lookup",
    routeLink: [URLS.Lookup, URLS.LookupDetails],
    component: LookupPage,
  },
  // {
  //   id: 3,
  //   title: "Instances",
  //   key: "instances",
  //   routeLink: URLS.KycInstances,
  //   component: KycInstances,
  // },
  {
    id: 5,
    title: "Settings",
    key: "settings",
    routeLink: URLS.Settings,
    component: Settings,
  },
];

const ServiceDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currenciesPayload, fetchingCurrencies } = useSelector(
    ({ kycInstances }) => kycInstances
  );

  useEffect(() => {
    if (history.location.pathname === "/products/kyc") {
      history.push(URLS.Overview);
    }
    dispatch(fetchSupportedCurrencies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="service-details">
      <section className="head-section d-flex">
        <div
          className="head-section__icon d-flex justify-content-center align-items-center"
          onClick={() => {
            window.location.replace("/products");
          }}
        >
          <i className="ri-arrow-drop-left-line"></i> KYC
        </div>
      </section>

      <section
        className="content-section sp-pt--24"
        data-testid="content-section"
      >
        <CustomTab data={tabItems}>
          <Switch>
            {tabItems.map(({ key, routeLink, component: Component }) => (
              <Route
                key={key}
                exact
                path={routeLink}
                render={() => (
                  <Component
                    currencies={currenciesPayload?.data?.data || []}
                    fetchingCurrencies={fetchingCurrencies}
                  />
                )}
              />
            ))}
          </Switch>
        </CustomTab>
      </section>
    </div>
  );
};

export default ServiceDetails;
